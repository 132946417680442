<template>
    <Dialog header='Deposit Playdough' v-model:visible="display" :modal="true" :draggable="false" class='deposit-playdough-modal'>

            <table class='transfer-table'>
                <tr>
                    <td class='label-container'> <span class='input-label'>Currency:</span></td>
                    <td class='value-container'>
                        <Dropdown class="currency-type-dropdown dropdown" v-model="currencyTypeOption" :options="currencyTypeOptions" placeholder="Select Currency" optionLabel="name" />
                    </td>
                </tr>
            </table>

            <template v-if="currencyTypeOption">
                <div class="transfer-destination-container">
                    <div class='input-label'>Select transfer destination:</div>
                    <div class="transfer-destination-options-container">
                        <div v-for="option in transferDestinationOptions" :key="option.id">
                            <RadioButton v-model="transferDestinationOption" :inputId="option.id" :name="option.label" :value="option.id"/>
                            <label :for="option.id">{{ option.label }}</label>
                        </div>
                    </div>
                </div>

                <TransfereeFundsSearch :searchForGroup="transferDestinationOption === transferDestinationOptionsIds.groupAccount" :isAdmin="isAdmin" @selected-transferee="selectedTransferee"/>

                <table class='transfer-table'>
                    <tr v-if="transferDestinationOption === transferDestinationOptionsIds.groupAccount || transferDestinationOption === transferDestinationOptionsIds.userAccount">
                        <td class='label-container'>
                            <span class='input-label' v-if="transferDestinationOption === transferDestinationOptionsIds.userAccount">Currently Selected User:</span>
                            <span class='input-label' v-else>Currently Selected Group:</span>
                        </td>
                        <td class='value-container'><b class="black">{{selectedTransfereeAccount ? selectedTransfereeAccount?.name : '-' }} </b></td>
                    </tr>
                    <tr>
                        <td class='label-container'> <span class='input-label'>Destination Account:</span></td>
                        <td class='value-container'>
                            <b class="black">{{ currencyTypeOption.id === CANADIAN_CURRENCY_TYPE['ID'] ? 'CAD Cash Account' : 'USD Cash Account' }}</b>
                        </td>
                    </tr>
                
                    
                    <tr>
                        <td class='label-container'><span class='input-label'>Deposit Amount:</span></td>
                        <td class='value-container'>
                            <span class="p-input-icon-right">
                                <i class="pi pi-dollar" />
                                <InputNumber class='amount-input' v-model="amount" :minFractionDigits="2" />
                            </span>
                        </td>
                    </tr>
                </table>
            </template>
            
       
        <div class='buttons-container'>
            <Button label='Cancel' class='green-button__secondary' @click="onClose()"/>
            <Button label='Deposit' class='green-button__secondary' @click="completeDeposit()" :disabled="disableDepositButton"/>
        </div>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';
import TransfereeFundsSearch from '../portfolio/TransfereeFundsSearch.vue';

import PortfolioService from '../../service/PortfolioService';

import { CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE } from '../../common/constants';
import EventBus from '../../event-bus';
import {mapState} from 'vuex';

const transferDestinationOptionsIds = {
    userAccount:"userAccount",
    groupAccount:"groupAccount"
}

const transferDestinationOptions = [
    {
        id: transferDestinationOptionsIds.userAccount,
        label: 'Another user account'
    },
    {
        id: transferDestinationOptionsIds.groupAccount,
        label: 'Group account'
    },
];

export default {
    name: 'DepositPlaydoughModal',
    props: {
        isAdmin: {
            type: Boolean
        }
    },
    components: {
        Dialog,
        Dropdown,
        InputNumber,
        RadioButton,
        TransfereeFundsSearch
    },
    

    data() {
        return {
            //CONSTANTS
            CANADIAN_CURRENCY_TYPE,
            transferDestinationOptions,
            transferDestinationOptionsIds,

            display: false,
            amount: null,
            selectedTransfereeAccount: null,
            transferDestinationOption: null,

            currencyTypeOption: null,
            currencyTypeOptions: [{id: CANADIAN_CURRENCY_TYPE['ID'], name: CANADIAN_CURRENCY_TYPE['NAME']}, {id: US_CURRENCY_TYPE['ID'], name: US_CURRENCY_TYPE['NAME']}],

            selectedItem: {displayText:""},
            filteredItems: null,
        }
    },

    computed: {
        ...mapState(['selectedGroupConduit']),

        disableDepositButton() {
            return !this.amount || !this.currencyTypeOption || !this.selectedTransfereeAccount;
        }
    },
    watch: {

        transferDestinationOption(newVal) {
            if (newVal != null) {
                this.selectedTransfereeAccount = null;
                EventBus.emit('refresh-transferee-search');
            }
        }
    },

    methods: {

        open() {
            this.amount = null;
            this.transferDestinationOption = transferDestinationOptionsIds.userAccount;
            this.currencyTypeOption = null;
            this.display = true;
        },

        onClose() {
            this.display = false;
        },

        selectedTransferee(transferee) {
            this.selectedTransfereeAccount = transferee;
        },

        completeDeposit() {
            const userId = this.selectedTransfereeAccount.userId || this.selectedTransfereeAccount.groupUserId
            
            PortfolioService.recordCashDeposit(userId, this.currencyTypeOption.id, this.amount).then((resp) => {
                if (resp.data.status === 'success') {
                    this.$toast.add({ severity: 'success', summary: 'The deposit completed successfully!', life: 3000 ,  group: 'center' });
                
                    if (this.transferDestinationOption === transferDestinationOptionsIds.groupAccount) {
                        if (this.$route.path.startsWith('/groups/profile') && this.selectedGroupConduit?.type === 'cashAccount' && this.selectedGroupConduit?.groupId === this.selectedTransfereeAccount?.groupId)  {
                            EventBus.emit('refresh-portfolio-overview');
                        }
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error depositing Playdough. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                }
            });

            this.onClose();
        }
    }
    
}
</script>

<style>
.deposit-playdough-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
}

.deposit-playdough-modal .p-dialog-header {
    padding: 16px;
}

.deposit-playdough-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .deposit-playdough-modal {
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .deposit-playdough-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .deposit-playdough-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .deposit-playdough-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.p-input-icon-right {
    width: 100%;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.dropdown:hover, .dropdown:active {
    border-color: #33CC99;
}
.dropdown:focus, .dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}

.p-button {
    border-radius: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-left: 5px;
}
.label-container {
    width: 55%;
    text-align: left;
}
.value-container {
    width: 45%;
    text-align: left;
}

.transfer-destination-container {
    margin-top: 12px;
}

.transfer-destination-container .input-label {
    text-align: left;
}

.transfer-destination-options-container {
    display: flex;
    flex-direction: row;
    margin: 12px 0 8px;
}

.transfer-destination-options-container div {
    align-items: center;
    display: flex;
    gap: 4px;
}
.transfer-destination-options-container > div {
    margin-right: 36px;
}
.transfer-destination-options-container > div:last-child {
    margin-right: 0px;
}

.transfer-table {
    width: 100%;
}
.transfer-table .value-container .black {
    color: black;
}

.currency-type-dropdown {
    width: 100%;
}
.amount-input {
    width: 100%;
}
.amount-input > ::v-deep(.p-inputtext) {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.amount-input:hover > ::v-deep(.p-inputtext) {
    border-color: #33CC99;
}
.amount-input:focus > ::v-deep(.p-inputtext) {
    box-shadow: none;
    border-color: #33CC99;
}

@media (max-width: 760px) {

}
</style>